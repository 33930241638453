import axios from 'axios';
/* eslint-disable */
const baseUrl = 'https://brasilapi.com.br/api/cep/v2';

const SearchAddressService = async (zipCode: string): Promise<any> => {
  let address = {
    street: "",
    number: "",
    complement: "",
    zone: "",
    city: "",
    state: "",
    zipCode: "",
    lat: "",
    long: ""
  };
  try {
    const { data } = await axios.get(`${baseUrl}/${zipCode}`);
    address.street = data.street;
    address.zipCode = data.cep;
    address.zone = data.neighborhood;
    address.city = data.city;
    address.state = data.state;
    address.lat = data.location?.coordinates?.latitude;
    address.long = data.location?.coordinates?.longitude;
    return { success: true, address };
  } catch (error) {
    return { success: false, address: null }
  }

}

export default SearchAddressService;

